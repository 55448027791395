import styled, { css, keyframes } from 'styled-components';

interface IMessage {
  isMe: boolean;
}

interface Igreen {
  className?: string;
}

export const Container = styled.div<Igreen>`
  z-index: 1;
width: 100%;
position: relative ;
 

 
.directionButtonLeft{
  background: #f15a23;
  border:0px;
  width: 40px;
  height: 40px ;
  border-radius: 50%;
  display: flex ;
  align-items:center;
  justify-content: center;
  position: absolute ;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  >svg{
    color: #000e40;
    width: 20px ;
    height: 20px
  }
  z-index : 1000;
}

.directionButtonRight{
  background: #f15a23;
  border:0px;
  width: 40px;
  height: 40px ;
  border-radius: 50%;
  display: flex ;
  align-items:center;
  justify-content: center;
  position: absolute ;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  >svg{
    color: #000e40;
    width: 20px ;
    height: 20px
  }
  z-index : 1000;
}

>div{
  width: 100%;


}
`;
