import React, { useEffect, useState } from 'react';

import { IconType } from 'react-icons/lib';




import api from '../../../../services/api';
import { useParams } from 'react-router-dom';
import { urlWebsite } from '../../../../config';

import { useConfiguration } from '../../../../hooks/Configuration';

import RenderPageImages from './modules/RenderPageImages';
import { ContainerLineUI } from '../../../../components/UIComponents/ContainerLine/styles';
import { ParagraphUI } from '../../../../components/UIComponents/Paragraph/styles';
import DefaultPageTemplate from './templates/default';
import TextHighlightPageTemplate from './templates/textHighlight';
import SinglePageTemplate from './modules/SinglePage';
import FluxoTemplate from './templates/fluxo';
import GalleryTemplate from './templates/gallery';
import { loadFromJson } from '../../../../utils/loadFromJson';
import { hash as hashPlatform } from '../../../../config';
import TextHighlightWithImagePageTemplate from './templates/textHighlightWithImage';
import HomeLeftPageTemplate from './templates/homeLeft';
import ImageGalleryFlowPageTemplate from './templates/imageGalleryFlow';
import VideoGallery from '../../../../components/VideoGallery';
import VideoGalleryPageTemplate from './templates/videoGallery';
import TextColumnsPageTemplate from './templates/textColumns';
import LocalPageTemplate from './templates/local';
import ImagesPageTemplate from './templates/images';
interface SignInData {
  email: string;
  password: string;
}

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

interface IProps {
  hash?: string;
}

const EventsPages: React.FC<IProps> = ({ hash = '' }) => {
  const { configuration } = useConfiguration();
  const { projectHash = configuration?.url, url = hash } = useParams<Record<string, any>>();
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageContent, setPageContent] = useState<Record<string, any>>({});

  const theme = configuration?.current_event_id_response?.theme || {};



  const templates: Record<string, any> = {
    default: <DefaultPageTemplate theme={theme} pageContent={pageContent} />,
    local: <LocalPageTemplate theme={theme} pageContent={pageContent} />,
    images: <ImagesPageTemplate theme={theme} pageContent={pageContent} />,
    homeLeft: <HomeLeftPageTemplate theme={theme} pageContent={pageContent} />,
    textHighlight: <TextHighlightPageTemplate theme={theme} pageContent={pageContent} />,
    textHighlightWithImage: <TextHighlightWithImagePageTemplate theme={theme} pageContent={pageContent} />,

    single_page: <SinglePageTemplate theme={theme} pageContent={pageContent} />,
    fluxo: <FluxoTemplate theme={theme} pageContent={pageContent} />,
    gallery: <GalleryTemplate theme={theme} pageContent={pageContent} />,
    imageGalleryFlow: <ImageGalleryFlowPageTemplate theme={theme} pageContent={pageContent} />,
    videoGallery: <VideoGalleryPageTemplate theme={theme} pageContent={pageContent} />,
    textColumns: <TextColumnsPageTemplate theme={theme} pageContent={pageContent} />,
  }

  const load = async () => {
    if (url) {
      setLoading(true);



      const item: Array<Record<string, any>> = await loadFromJson({ hash: hashPlatform, project_id: projectHash, table: `EventPage-${url}` })

      if (!item) {

        const response = await api.get(`/show-page/${projectHash}/${url}`);
        if (response) {
          setPageContent(response.data);
          setReady(true);
        }
        else {
          setReady(true);
        }
      }
      else {



        setPageContent(item?.[0]);
        setReady(true);

      }


      setLoading(false);
    }
  }

  useEffect(() => {

    load();

  }, [url, projectHash])

  const images = pageContent?.images?.length > 0 ? pageContent?.images?.reduce((prev: JSX.Element[], image: Record<string, any>) => {

    prev.push(<img style={{ width: '100%', maxWidth: '100%' }} src={`${urlWebsite}/${image?.url}`} />);
    return prev;
  }, [] as JSX.Element[]) : [];


  return <>{templates?.[pageContent?.theme_data?.template] ? templates?.[pageContent?.theme_data?.template] : templates?.['default']}</>
}
export default EventsPages;
