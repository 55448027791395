import React, { useCallback, useRef, useEffect, useState } from 'react';
import { FiLogIn, FiMail, FiLock, FiUser } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory, useParams } from 'react-router-dom';
// import logo from '../../assets/logo-compressed.png';

import { supportNumber, authTitle, logo, urlWebsite } from '../../config';
import { Container, Content, AnimatedDiv2, ErrorContainer } from './styles';
import Input from '../../components/Forms/Input';
import Button from '../../components/Button';
import getValidationErrors from '../../utils/getValidationErrors';

import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';
import Whatsapp from '../../components/Whatsapp';
import { useLanguage } from '../../hooks/Language';
import Loader from '../../components/Loader';
import { FaWhatsapp } from 'react-icons/fa';
import Logo from '../../components/Logo';
import { useConfiguration } from '../../hooks/Configuration';
import Axios from 'axios';
import { useModal } from '../../hooks/Modal';
import { useLoading } from '../../hooks/LoadingHook';
import api from '../../services/api';



interface SignInData {
  email: string;
  password: string;
  name: string;
}

interface IParams {
  redirect?: string;
}

interface IParamsURL {
  projectHash?: string;
  eventHash?: string;
}

const Presencial: React.FC<IParams> = ({ redirect = '/' }) => {
  const history = useHistory();
  const [withPassword, setWithPassword] = useState(false);
  const { signIn } = useAuth();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const { translate } = useLanguage();
  const [errorMessage, setErrorMessage] = useState<Record<string, string>>({});
  const [manutencao, setManutencao] = useState(false);
  const { addModal } = useModal();
  const { projectHash, eventHash } = useParams<IParamsURL>();

  const { configuration, setConfiguration } = useConfiguration();
  const { addLoading, removeLoading } = useLoading();


  const checkCourtesy = async (cortesyHash) => {

    addLoading({ title: 'Validando cortesia', key: 'cortesia' })

    try {
      const confirm = await api.get(`/v3/validate-cortesy-owner/${cortesyHash}/${configuration?.url}`);




      removeLoading('cortesia');
      return confirm?.data?.hash;
    }
    catch (err) {
      removeLoading('cortesia');
      return false;
    }


  }

  const handleSubmit = useCallback(
    async (data: Record<string, any>) => {
      setLoading(true);




      formRef.current?.setErrors({});

      if (!data?.code) {
        addModal({
          title: '', content: <>
            <h2 className='primaryColor'>Insira seu código do convite</h2>

          </>, theme: 'whiteModalMini'
        });
        setLoading(false);
        return;
      }

      const check = await checkCourtesy(data?.code);

      if (!check) {
        addModal({
          title: '', content: <>
            <h2 className='primaryColor'>Código não identificado</h2><br />
            <p style={{ textAlign: 'center' }}>Verifique seu código ou entre em contato com a organização.</p>
          </>, theme: 'whiteModalMini'
        })
        setLoading(false);
        return;
      }
      else {
        window.location.href = `/app/${configuration?.url}/validar-cortesia/${check}`
      }

      setLoading(false)

      // window.location.href = `/app/${configuration?.url}/dashboard`;
      //  window.location.href = redirect !== '/' ? `/dashboard/${redirect}` : (projectHash ? `/app/${projectHash}/dashboard/` : '/projects');
      return;




    },
    [signIn, addToast, history],
  );

  return (<>
    {loading && <Loader message="Validando sua cortesia ..." />}
    <Container>
      <Content>
        <h2 style={{ color: '#fff' }}>{configuration?.title}</h2>
        <AnimatedDiv2 className='shadowBox'>

          {manutencao ? <div className="onlineMessage ">

            <h2 className="defaultMarked"><div />{translate('Estamos em manutenção')}</h2>
            <p style={{ color: '#333', textAlign: 'center', marginTop: '20px' }}>
              {translate(
                'Voltamos em breve!',
              )}
            </p><br />
          </div> : <Form className="form" ref={formRef} onSubmit={handleSubmit}>


            <h2 style={{ margin: '20px auto' }} className="titleElement  primaryColor ">{translate('EVENTO PRESENCIAL')}</h2>

            <p style={{ color: '#333', fontSize: '14px', maxWidth: '400px', justifyContent: 'center', margin: '15px auto' }}>Caso você tenha sido convidado para o evento presencial, insira o seu código de cortesia abaixo.</p>

            <Input name="code" placeholder={translate('Código do convite')} />


            {errorMessage && errorMessage.title && <ErrorContainer>
              <h2>{errorMessage.title}</h2>
              <p dangerouslySetInnerHTML={{ __html: errorMessage.description }} />
            </ErrorContainer>}
            <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
              <button className='greenButton2' style={{ margin: '0px auto', marginTop: '40px' }} type="submit">{translate('Enviar')}</button>
            </aside>


            <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}><Whatsapp
              number={supportNumber}
              text={`${translate(`Olá, estou no ${authTitle}`)}`}
              message={translate('Suporte Técnico')}
            />

            </aside>
          </Form>}
        </AnimatedDiv2>
      </Content>
    </Container></>
  );
};
export default Presencial;
