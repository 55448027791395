import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { Link, useHistory, useParams } from 'react-router-dom';
import { FormHandles } from '@unform/core';

import * as Yup from 'yup';

import { Container, Content, Background, AnimatedDiv } from './styles';

import getValidationErrors from '../../utils/getValidationErrors';
import api from '../../services/api';
import { useToast } from '../../hooks/Toast';

import FormContainer from './FormContainer';
import { useLanguage } from '../../hooks/Language';


interface SignUpFormData {
  name: string;
  email: string;
  password: string;
}

interface IParams {
  projectHash: string;
  eventHash: string;
}

const SignUp: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const [withPassword, setWithPassword] = useState(false);
  const { projectHash, eventHash } = useParams<IParams>();
  const formRef = useRef<FormHandles>(null);
  const { translate } = useLanguage();

  useEffect(() => {
    window.scrollTo(0, 0);

    // Create script element
    const script = document.createElement('script');

    // Set script attributes
    script.src = 'https://d335luupugsy2.cloudfront.net/js/loader-scripts/6406c0cb-7e76-4e81-93a9-c3686345d560-loader.js';
    script.async = true;

    // Append script to the body
    document.body.appendChild(script);

    // Remove script on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, [])



  return (
    <Container>
      <Content>
        <AnimatedDiv>
          <Link to={eventHash ? `/app/${projectHash}/event/${eventHash}/login/` : `/app/${projectHash}/login/`} style={{ color: 'rgb(0,15,64)' }}>
            <FiArrowLeft />
            {translate('Voltar para login')}
          </Link>

          <h2>{translate('Inscrição')}</h2>
          <FormContainer />
        </AnimatedDiv>
      </Content>
    </Container>
  );
};

export default SignUp;
