import React from 'react';
import FadeInOnScroll from '../../../../../../components/FadeInOnScroll';
import ImageGallery from '../../../../../../components/ImageGallery';

import ContainerLine from "../../../../../../components/UIComponents/ContainerLine";
import { ContainerLineUI } from "../../../../../../components/UIComponents/ContainerLine/styles";

import { ParagraphUI } from "../../../../../../components/UIComponents/Paragraph/styles";
import ParagraphDangerous from '../../../../../../components/UIComponents/ParagraphDangerous';
import { ParagraphHighlightUI, TitleHighlightUI } from '../../../../../../components/UIComponents/TitleHighlight/styles';
import VideoBackground from "../../../../../../components/VideoBackground";
import { urlWebsite } from "../../../../../../config";
import ImageGalleryFlowComponent from '../../modules/imageGalleryFlowComponent';
import RenderPageList from '../../modules/RenderList';
import RenderPageImages from "../../modules/RenderPageImages";

const TextColumnsPageTemplate = ({ pageContent, theme }: Record<string, any>) => {
  const { hash } = pageContent;

  return (
    <>

      <ContainerLineUI className=' full-width containerPad' id={hash} style={{ display: 'flex', zIndex: 1000, alignItems: 'center', flexDirection: pageContent?.orientation === 'column' ? 'column' : 'row', background: pageContent?.theme_data?.background ? pageContent?.theme_data?.background : '#fff' }} >




        {pageContent?.data_list?.length > 0 ? <div className='row-to-column gap' style={{ marginTop: '50px', alignItems: 'flex-start' }}>
          {pageContent?.data_list?.map(item => {

            return <div className='column max-300' style={{ width: '100%' }}>
              <h2 className='hugeText3 highlightBlueTitle ' style={{ maxWidth: '257px', display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '60px', marginBottom: '15px' }}>{item?.title}</h2>
              <div className='defaultColor text-center' style={{ textAlign: 'center', fontSize: '14px' }} dangerouslySetInnerHTML={{ __html: item?.text }} />
            </div>

          })}

        </div> : <></>}
      </ContainerLineUI>
    </>
  );


}

export default TextColumnsPageTemplate;