import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface ISlider {
  autoplay?: boolean;
  speed?: number;
  slidesToShow?: number;
  slidesToScroll?: number;
  arrows?: boolean;
  [key: string]: any;
}

const SliderContainer: React.FC<ISlider> = ({
  autoplay = true,
  speed = 2000,
  slidesToShow = 1,
  slidesToScroll = 1,
  arrows = false,
  children,
  centerMode = true,
}) => {
  const settings = {
    infinite: true,
    autoplay,
    speed,
    slidesToShow,
    slidesToScroll,
    dots: false,
    arrows: false,


    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return <Slider  {...settings}>{children}</Slider>;
};

export default SliderContainer;
