import React, { useEffect, useState } from 'react';

import { FaPlus, FaList } from 'react-icons/fa';
import { FiGrid } from 'react-icons/fi';
import {
  Container,
  Grid,
  Speaker,
  SpeakerLine,
  SpeakerListContainer,
  ContainerWhite,
  SpeakerDiv,
  SpeakerBig,
} from './styles';
import { urlWebsite } from '../../config';

import { Modal } from '../ModalContainer/styles';
import ContactIcons from './modules/social';
import { useConfiguration } from '../../hooks/Configuration';
import SliderComponent from '../SliderContainer';
import { simpleScrollTo } from '../Menu';

interface ISpeakerData {
  title?: string;
  name: string;
  photo: string;
  position?: string;
  description?: string;
  id: number;
  category_response?: Record<any, string>;
  social?: Record<any, string>;
  company?: string;

}

interface IGrid {
  id?: string;
  speakers?: Array<ISpeakerData>;
  slider?: boolean;
  theme?: Record<string, any>;
}

interface IModal {
  speaker: ISpeakerData;
}

interface ISpeaker {
  background: string;
}

interface ISpeakers {
  type?: 'list' | 'grid';
  speakers?: Array<ISpeakerData>;
  title: string;
  slider?: boolean;
  theme?: Record<string, any>;
}



const SpeakerGrid: React.FC<IGrid> = ({
  speakers = [],
  id = 'palestrantes',
  children,
  slider = false,
  theme
}) => {
  const [activeSpeaker, setActiveSpeaker] = useState('');
  const [selectedSpeaker, setSelectedSpeaker] = useState<ISpeakerData>(
    {} as ISpeakerData,
  );

  const { configuration } = useConfiguration();


  const showSpeaker = speaker => {
    if (speaker._id === activeSpeaker) {
      setActiveSpeaker('');
      setSelectedSpeaker({} as ISpeakerData);
    } else {
      setActiveSpeaker(speaker._id);
      setSelectedSpeaker(speaker);
    }
  };

  const [currentSpeaker, setCurrentSpeaker] = useState<Record<string, any>>({});

  useEffect(() => {
    const index = Math.floor(Math.random() * speakers.length);

    setCurrentSpeaker(speakers[index]);

  }, [speakers])


  return (
    <Container id="palestrantes">
      <div className="title" style={{ width: '100%' }}>{children}</div>


      <div className='row-to-column full-width' style={{ alignItems: 'stretch' }}>


        <SpeakerBig background={`${urlWebsite}/${currentSpeaker?.photo}`} />
        <div className='column full-width' >
          <div className='column full-width' style={{ background: 'rgb(255, 197, 0)', color: '#272727', padding: '30px', textAlign: 'left', }}>
            <h3 className='full-width' style={{ color: '#272727', fontSize: '24px', textTransform: 'uppercase', textAlign: 'left' }}>{currentSpeaker.title ? `${currentSpeaker.title} ` : ''}{currentSpeaker.name}</h3>
            {currentSpeaker.position && <p className='positionSpeaker full-width' style={{ color: '#272727', marginBottom: '5px', textAlign: 'left' }}>{currentSpeaker.position}</p>}

          </div>

          <div className='column full-width' style={{ background: '#272727', flexGrow: 1, padding: '30px', textAlign: 'left', color: '#fff' }}>
            <p dangerouslySetInnerHTML={{ __html: currentSpeaker?.description || '' }} />
          </div>
        </div>


      </div>


      <Grid >
        {speakers.map(speaker => {
          return (<SpeakerDiv onClick={() => { setCurrentSpeaker(speaker); simpleScrollTo('palestrantes') }}>
            <div className='speakerPhotoDiv'>
              <Speaker
                className='speakerPhoto'

                background={
                  speaker.photo
                    ? `${urlWebsite}/${speaker.photo}`
                    : '/apoio/icone.jpeg'
                }
              >
                <h3 style={{ color: '#fff', position: 'absolute', bottom: '0px', left: '0px', fontSize: '12px', textAlign: 'center', width: '100%', paddingTop: '100px', paddingBottom: '15px', background: 'linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%)' }}>{speaker.title ? `${speaker.title} ` : ''}{speaker.name}</h3>

              </Speaker></div>


          </SpeakerDiv>
          );
        })}
      </Grid>
      {
        activeSpeaker !== '' && (
          <div style={{ position: 'fixed', background: 'rgba(0,0,0,0.5)', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column', top: '0px', left: '0px', width: '100%', height: '100vh', overflowY: 'auto', zIndex: 7000000000000000 }}>
            <div style={{ width: '100%', maxWidth: '800px', background: 'none' }}>
              <button
                style={{ marginBottom: '10px', marginTop: '25px' }}
                className="greenButton2 fadeIn scaleUp"
                type="button"
                onClick={() => showSpeaker(selectedSpeaker)}
              >
                Fechar
              </button>
              <SpeakerModal speaker={selectedSpeaker} />
            </div>
          </div>
        )
      }
    </Container >
  );
};

export const SpeakerModal: React.FC<IModal> = ({ speaker }) => {

  const { configuration } = useConfiguration();
  return (
    <>
      <ContainerWhite className={`shadow ${configuration?.theme_data?.speakers_className && `modal_summit modal_${configuration?.theme_data?.speakers_className}`} `} style={{ alignItems: 'flex-start', borderRadius: '15px', flexDirection: 'column', background: '#f3f3f3' }}>

        <div className="p50 p600" style={{ textAlign: 'center', display: 'flex', }}>
          <div className='speakerPhotoDiv'>
            <img
              src={
                speaker.photo
                  ? `${urlWebsite}/${speaker.photo}`
                  : 'assets/evento/logo2.png'
              }
              style={{ width: '100%', maxWidth: '180px', minWidth: '180px' }}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', padding: '20px', alignItems: 'flex-start' }}>
            <h2 style={{ fontSize: '20px', color: '#333', textAlign: 'left', width: '100%' }}>{speaker.title ? `${speaker.title} ` : ''}{speaker.name} </h2>
            <strong style={{ color: '#333', fontSize: '14px', textAlign: 'left', width: '100%', minWidth: '300px' }}> {speaker.company} </strong>
            {/* <strong style={{ color: '#333', fontSize: '12px', textAlign: 'left', width: '100%', minWidth: '300px' }}> {speaker.position} </strong> */}
            <p style={{ color: '#333', fontSize: '12px', textAlign: 'left', width: '100%' }}>{speaker.category_response && speaker.category_response.title}</p>
            <br />    <p style={{ color: '#333' }}

              dangerouslySetInnerHTML={{
                __html: speaker.description || '',
              }}
            />
          </div>
        </div>

      </ContainerWhite>
    </>
  );
};

export const SpeakerList: React.FC<IGrid> = ({
  speakers = [],
  id = 'palestrantes',
  children,
}) => {
  const [activeSpeaker, setActiveSpeaker] = useState('');
  const [selectedSpeaker, setSelectedSpeaker] = useState<ISpeakerData>(
    {} as ISpeakerData,
  );



  const showSpeaker = speaker => {

    if (speaker._id === activeSpeaker) {
      setActiveSpeaker('');
      setSelectedSpeaker({} as ISpeakerData);
    } else {
      setActiveSpeaker(speaker._id);
      setSelectedSpeaker(speaker);
    }
  };

  return (
    <Container>
      <div className="title">{children}</div>


      <SpeakerListContainer>
        {speakers.map(speaker => {
          return (
            <SpeakerLine
              onClick={() => showSpeaker(speaker)}
              background={
                speaker.photo
                  ? `${urlWebsite}/${speaker.photo}`
                  : 'assets/evento/logo2.png'
              }
            >
              <div className="speakerPhoto" />

              <div className="speakerInfo">

                <h2>{speaker.title ? `${speaker.title} ` : ''}{speaker.name}</h2>
                <p>{speaker.category_response && speaker.category_response.title}</p>
                <p>{speaker.position}</p>
              </div>
            </SpeakerLine>
          );
        })}
      </SpeakerListContainer>

      {activeSpeaker !== '' && (
        <div style={{ position: 'fixed', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', top: '0px', left: '0px', width: '100%', height: '100vh', paddingTop: '50px', overflowY: 'auto', zIndex: 7000000000000000 }}>
          <button
            style={{ marginBottom: '10px', zIndex: 7000000000, transform: 'translateX(-0px)' }}
            className="closeButton fadeIn scaleUp"
            type="button"
            onClick={() => showSpeaker(selectedSpeaker)}
          >
            Fechar
          </button>
          <SpeakerModal speaker={selectedSpeaker} />
        </div>
      )}
    </Container>
  );
};

export const SpeakersContainer: React.FC<ISpeakers> = ({ speakers, title, slider = false, theme = {} }) => {
  const [type, setType] = useState('grid');
  const { configuration } = useConfiguration();

  if (type === 'grid') {
    return (
      <SpeakerGrid theme={theme} slider={slider} speakers={speakers}>
        <h2 className='titleElement' style={{ color: 'rgb(39, 39, 39)', textAlign: 'center', width: '100%', justifyContent: 'center', alignItems: 'center', textTransform: 'uppercase' }}>
          {title}
          {' '}

        </h2>
        {configuration?.current_event_id_response?.program_description ? <p style={{ color: theme?.textColor || 'rgb(3,88,124)', textAlign: 'center', width: '100%', fontSize: '14px', maxWidth: '800px', lineHeight: '18px' }} dangerouslySetInnerHTML={{ __html: configuration?.current_event_id_response?.program_description }} /> : <></>}

      </SpeakerGrid>
    );
  }
  return <></>;
};

export default SpeakerGrid;
