import styled from 'styled-components';

export const Container = styled.div`
  min-height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  color: #fff;
  justify-content: center;
  position: relative;
  flex-wrap: wrap;
 
  align-items: flex-start;
  width: 100%;
  gap: 10px;
`;
