
import React, { useEffect, useState } from 'react';
import { FaMapMarker } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { scrollTo } from '../../../../components/Menu';
import { urlWebsite } from '../../../../config';
import { useConfiguration } from '../../../../hooks/Configuration';
import { useLanguage } from '../../../../hooks/Language';
import {

    Capa,



} from '../Themes/SinglePageTheme/styles';

import { Container } from './styles';

interface IProps {
    list: Array<Record<string, any>>;
    title: string;
}

const SpeakersListByCountry: React.FC<IProps> = ({ list, title }) => {
    const { configuration, projectHash } = useConfiguration();
    const { translate } = useLanguage();

    const [theme, setTheme] = useState({
        titleColor: configuration?.current_event_id_response?.theme?.title_color || '#fff',
        textColor: configuration?.current_event_id_response?.theme?.text_color || '#fff',
        buttonColor: configuration?.current_event_id_response?.theme?.button_color || 'rgb(78, 27, 129)',
        buttonBackground: configuration?.current_event_id_response?.theme?.button_background || '#3FB8EB',
    })


    useEffect(() => {
        setTheme({
            titleColor: configuration?.current_event_id_response?.theme?.title_color || '#fff',
            textColor: configuration?.current_event_id_response?.theme?.text_color || '#fff',
            buttonColor: configuration?.current_event_id_response?.theme?.button_color || 'rgb(78, 27, 129)',
            buttonBackground: configuration?.current_event_id_response?.theme?.button_background || '#3FB8EB',
        })
    }, [configuration])




    return <aside style={{ marginBottom: '25px', background: 'rgba(255,255,255,0.95)', display: 'flex', alignItems: 'flex-start', justifyContent: 'center', width: '100%' }}>

        <Container style={{ flexDirection: 'column' }}  >
            <div className='content'>
                <h2 className='titleElement'>{title}</h2>
            </div>
            <div className='contentItems'>
                <table className='table' style={{ maxWidth: '320px', minWidth: '320px' }}>
                    <tbody>
                        {list.map((item, index) => {

                            const isOk = index < (list.length / 3);

                            return isOk ? <tr><td style={{ minHeight: '85px', height: '85px' }}><img style={{ width: '60px', minWidth: '60px', maxWidth: '100%' }} src={`/apoio/${item?.country}.png`} /></td>
                                <td style={{ minHeight: '85px', height: '85px' }}><strong style={{ color: 'rgb(44, 61, 77)' }}>{item?.name}</strong>
                                    <p style={{ fontSize: '11px', color: '#777' }}>{item?.university}</p>
                                    <p style={{ fontSize: '10px', marginTop: '5px', color: '#777' }}>{item?.city}</p>
                                </td></tr> : <></>
                        })}
                    </tbody>
                </table>

                <table className='table' style={{ maxWidth: '320px', minWidth: '320px' }}>
                    <tbody>
                        {list.map((item, index) => {
                            const isOk = index >= (list.length / 3) && index < ((list.length / 3) * 2);
                            return isOk ? <tr><td style={{ minHeight: '85px', height: '85px' }}><img style={{ width: '60px', minWidth: '60px', maxWidth: '100%' }} src={`/apoio/${item?.country}.png`} /></td>
                                <td style={{ minHeight: '85px', height: '85px' }}><strong style={{ color: 'rgb(44, 61, 77)' }}>{item?.name}</strong>
                                    <p style={{ fontSize: '11px', color: '#777' }}>{item?.university}</p>
                                    <p style={{ fontSize: '10px', marginTop: '5px', color: '#777' }}>{item?.city}</p>
                                </td></tr> : <></>
                        })}
                    </tbody>
                </table>

                <table className='table' style={{ maxWidth: '320px', minWidth: '320px' }}>
                    <tbody>
                        {list.map((item, index) => {
                            const isOk = index >= ((list.length / 3) * 2);
                            return isOk ? <tr><td style={{ minHeight: '85px', height: '85px' }}><img style={{ width: '60px', minWidth: '60px', maxWidth: '100%' }} src={`/apoio/${item?.country}.png`} /></td>
                                <td style={{ minHeight: '85px', height: '85px' }}><strong style={{ color: 'rgb(44, 61, 77)' }}>{item?.name}</strong>
                                    <p style={{ fontSize: '11px', color: '#777' }}>{item?.university}</p>
                                    <p style={{ fontSize: '10px', marginTop: '5px', color: '#777' }}>{item?.city}</p>
                                </td></tr> : <></>
                        })}
                    </tbody>
                </table>

            </div>
        </Container>
    </aside>
}

export default SpeakersListByCountry;