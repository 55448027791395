import React, { Children, useEffect, useState } from 'react';
import { urlWebsite } from '../../config';
import SlideList from '../../pages/DashboardPages/features/SlideList';

import { Container } from './styles';

interface ISlide {
  url: string;
  link_url: string;
  title?: string;
}

interface IVideoBackgroundProps {
  slides: Array<ISlide>;
  speed?: number;
  padding?: string;
}

const ImageSlider: React.FC<IVideoBackgroundProps> = ({
  slides,
  speed = 20000,
  padding = '56.25%'

}) => {
  const [currentSlide, setCurrentSlide] = useState<ISlide>(slides[0] ? slides[0] : {} as ISlide);



  const sendToLink = (link) => {
    window.open(
      link,
      '_blank' // <- This is what makes it open in a new window.
    );

  }

  return (
    <>
      <Container style={{ paddingBottom: padding, width: '100%' }} onClick={() => { return currentSlide?.link_url ? sendToLink(currentSlide?.link_url) : false }} background={currentSlide?.url ? `${urlWebsite}/${currentSlide?.url}` : ''} >


        <h3 style={{ color: '#fff', position: 'absolute', bottom: '0px', left: '0px', fontSize: '12px', textAlign: 'center', width: '100%', paddingTop: '100px', paddingBottom: '15px', background: 'linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%)' }}>{currentSlide.title ? `${currentSlide.title} ` : ''}{currentSlide.title}</h3>


      </Container>

      <div className='row pad' style={{ gap: '5px', justifyContent: 'center', width: '100%' }}>

        {slides.map((item, index) => {
          return <div onClick={() => setCurrentSlide(item)} style={{ width: '10px', height: '10px', background: currentSlide?.url === item?.url ? 'rgb(255, 197, 0)' : '#333', cursor: 'pointer', borderRadius: '50%' }} />
        })}

      </div>

    </>

  );
};

export default ImageSlider;
