import React from 'react';

import ContainerLine from "../../../../../../components/UIComponents/ContainerLine";
import { ContainerLineUI } from "../../../../../../components/UIComponents/ContainerLine/styles";

import { ParagraphUI } from "../../../../../../components/UIComponents/Paragraph/styles";
import VideoBackground from "../../../../../../components/VideoBackground";
import { urlWebsite } from "../../../../../../config";
import RenderPageImages from "../../modules/RenderPageImages";
import ImageGallery from "../../../../../../components/ImageGallery";
const LocalPageTemplate = ({ pageContent, theme }: Record<string, any>) => {
  const { hash } = pageContent;

  return (
    <>

      <ContainerLineUI className='containerPad' id={hash} style={{ position: 'relative', zIndex: 1001, flexDirection: pageContent?.orientation === 'column' ? 'column' : 'row', background: pageContent?.theme_data?.background ? pageContent?.theme_data?.background : pageContent?.image ? `url("${urlWebsite}/${pageContent?.image}")` : '#fff', minHeight: '60vh', width: '100%' }} >
        <h2 className='titleElement' style={{ color: pageContent?.theme_data?.title_color ? pageContent?.theme_data?.title_color : '#333', padding: '0px 20px', textAlign: 'center' }}>{pageContent?.title}</h2>

        <div className='row-to-column'>

          {pageContent?.map_link ? <div style={{ width: '100%', maxWidth: '250px', borderRadius: '15px', overflow: 'hidden' }}  > <iframe src={pageContent?.map_link} width="600" height="250" style={{ 'border': 0 }} allowFullScreen={true} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe> </div> : <></>}

          <div className='column column-start-center pad gap'>

            <ParagraphUI className='YellowTitle' color={pageContent?.theme_data?.text_color ? pageContent?.theme_data?.text_color : '#333'} dangerouslySetInnerHTML={{ __html: pageContent?.description || '' }} />

            {pageContent?.files && pageContent?.files.map((item: Record<string, any>) => {
              return <a href={`${urlWebsite}/${item.url}`} target="_blank"><button className='defaultButton'>{item.title}</button></a>
            })}

            {pageContent?.buttons?.length > 0 ? <div style={{ margin: '20px auto' }}>
              {pageContent?.buttons && pageContent?.buttons.map((itemButton: Record<string, any>) => {
                return <a href={`${itemButton.link}`} ><button className={itemButton.class_name || 'greenButton2 shadow'} style={{ background: theme?.button_background, color: theme?.button_color }}>{itemButton.title}</button></a>
              })}
            </div> : <></>}
            <ImageGallery data={pageContent?.images} />
          </div>

        </div>


        {pageContent?.video ? <VideoBackground path={pageContent?.video} /> : <></>}


      </ContainerLineUI>
    </>
  );


}

export default LocalPageTemplate;