import React, { useEffect } from 'react';
import { MdChat, MdChatBubble } from 'react-icons/md';
import { Container, Sponsor, SponsorGrid } from './styles';
import { Button } from '../../components/Button/styles';
import Sponsors from '../Sponsors';
import Particles from 'react-particles-js';
import ImageSliderBackground from '../../components/ImageBackgroundSlider';
import { FaFacebook, FaInstagram, FaInstagramSquare } from 'react-icons/fa';

const FooterLayout: React.FC = ({ children }) => {
  return (<>

    <div style={{ zIndex: -1 }}>
      <Particles
        params={{
          particles: {
            lineLinked: {
              enable: true,
              distance: 250
            },
            move: {
              speed: 2,
              direction: 'top-right',
              collisions: false,
              outModes: {
                default: 'out',
                left: 'out',
                right: 'out',
                bottom: 'out',
                top: 'out',
              },


            },
            opacity: {
              value: 0.4,
              random: false,
              animation: {
                enable: false
              }

            },
            shape: {
              type: 'circle',
              polygon: {

                nb_sides: 6,

              },

            },

            color: { value: 'rgb(171, 230, 255)' },

            number: {
              density: {
                enable: true,
                value_area: 2400,
              },
              value: 60,
            },
            size: {
              value: 4,
              random: true,
            },
          },
        }}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '100%',
          zIndex: 1
        }}
      />
    </div>
  </>
  );
};

export default FooterLayout;
